import "../../styles/home.css";

export const Sectoin4 = () => {
  return (
    <section className={` w-screen  bg-gray  ${firstSection}`}>
      <p className="text-2xl font-bold text-black mt-16 md:text-4xl">
        About the Author
      </p>
      <span className="md:grid md:grid-cols-2  md:mt-16 pb-8 ">
        <div className="flex justify-center md:justify-center md:-ml-44  md:items-center ">
          <img src="/authorimg2.png" className="rounded-full mt-6 md:w-5/12" />
        </div>
        <span className="flex flex-col items-center aboutauthor md:pl-4 md:-ml-44 ">
          <p className="text-2xl font-bold text-black mt-6 md:text-left w-full text-center md:px-0  md:mr-1">
            John Doe
          </p>
          <p className="text-black text-center text-xl mt-6 px-6 leading-8 md:text-left md:px-0 ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl
            feugiat et turpis id cras feugiat imperdiet viverra. Nisi, feugiat
            amet praesent quam enim diam fringilla. Rhoncus feugiat urna, nibh
            viverra mauris, habitasse nulla tincidunt potenti. Aliquet sem
            quisque gravida magna pharetra elementum.
          </p>
          <p className="text-black text-center text-xl mt-6 px-6  leading-8 mb-16 md:text-left md:px-0 ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl
            feugiat et turpis id cras feugiat imperdiet viverra. Nisi, feugiat
            amet praesent quam enim diam fringilla. Rhoncus feugiat urna, nibh
            viverra mauris, habitasse nulla tincidunt potenti. Aliquet sem
            quisque gravida magna pharetra elementum.
          </p>
        </span>
      </span>
    </section>
  );
};
const firstSection = "text-white flex flex-col items-center py-2 md:px-16";
