import { useEffect, useState } from "react";
import "../../styles/home.css";
interface Props {
  data: any;
}
export const Section1 = ({ data }: Props) => {
  const [bgUrl, setBgurl] = useState("");
  useEffect(() => {
    data.map((item: any) => setBgurl(item.backimg1));
  }, [data]);
  return (
    <section
      className={`firstSection w-screen min-h-screen mt-14 pb-12 md:pt-20 ${firstSection}`}
      style={{
        backgroundImage: `url(${bgUrl})`,
      }}
    >
      {data.map((item: any) => (
        <>
          <p className="font-bold text-2xl mt-8 mb-2 md:hidden">{item.title}</p>
          <p
            style={{ fontFamily: "Great Vibes" }}
            className="md:hidden mb-12 text-2xl"
          >
            {item.subtitle}
          </p>
          <span className="flex flex-col items-center md:flex-row md:px-20 md:py-10 md:justify-between md:space-x-4">
            <div className={`${imageContainer} mb-8 md:mb-0 md:w-full md:p-0 `}>
              <img
                className="section1Book"
                src={`
              ${item.image}`}
              />
            </div>
            <span className="md:flex md:flex-col md:w-full  ">
              <p className="font-bold text-2xl mb-2 hidden md:block md:text-5xl">
                {item.title}
              </p>
              <p
                style={{ fontFamily: "Great Vibes" }}
                className="md:text-4xl mb-12 hidden md:block"
              >
                {item.subtitle}
              </p>

              <p className="text-center text-md py-2 px-8 leading-8 tracking-wide md:text-left md:px-0 lg:text-xl">
                {item.description}
              </p>
              <p className="text-center text-md py-4 px-16 leading-8 tracking-wide mb-6 md:text-left md:px-0 lg:text-xl">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl
                feugiat et turpis id cras feugiat imperdiet viverra.
              </p>
            </span>
          </span>
          <iframe
            className="videoplayer mb-8 md:mb-12"
            src="https://www.youtube.com/embed/9YffrCViTVk"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
          <button className="bg-yellow text-xl font-bold py-4 w-10/12 rounded-md mb-16 mt-8 md:text-3xl hover:opacity-90">
            BUY NOW
          </button>
        </>
      ))}
    </section>
  );
};
const firstSection = "text-white flex flex-col items-center py-2 md:px-16";
const imageContainer = "w-11/12 md:w-full flex items-center justify-center";
