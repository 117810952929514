import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

interface Props {
  data: any;
}
export const Section2 = ({ data }: Props) => {
  const [expand, setExpand] = useState("");
  const [len, setlen] = useState(0);
  const [viewMore, setViewMore] = useState(0);
  const toggle = (id: any) => {
    if (expand === id) setExpand("");
    else setExpand(id);
  };
  const [bgUrl, setBgUrl] = useState("");
  useEffect(() => {
    data.map((item: any) => setBgUrl(item.backimg2));
  }, [data]);
  useEffect(() => {
    data.map((items: any) => setlen(items.chapters.length));
  });
  // data.map((items: any) => console.log(items.chapters.length));
  return (
    <section
      className={`secondSection  w-screen pb-12  ${firstSection}`}
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      <p className="text-black font-bold text-2xl mt-12 mb-16 md:text-4xl">
        Chapter wise Summary
      </p>

      {/* acoridon */}
      <div className="w-full " style={{ minHeight: "50vh" }}>
        {data.map((items: any) => (
          <>
            {items.chapters.slice(0, viewMore + 4).map((item: any, i: any) => (
              <div className="md:mb-10">
                <div className="flex flex-col w-full items-center">
                  <div
                    className={`${acoridonContainer}`}
                    onClick={() => toggle(i)}
                  >
                    <p className="text-sm font-semibold md:text-lg lg:text-xl ">
                      {item.name}
                    </p>
                    <p
                      className={`bg-white text-brown px-2 font-semibold text-xl rounded-full flex items-center justify-center cursor-pointer md:text-3xl ${
                        expand === i && "transform rotate-45"
                      }`}
                    >
                      +
                    </p>
                  </div>
                  {/* expanded */}
                  <AnimatePresence>
                    {expand === i ? (
                      <motion.div
                        layoutId={i}
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{ duration: 1, type: "spring" }}
                        className={`${expandedAcoridon} `}
                        style={{ boxShadow: "#cacaca 0 0 0.5rem" }}
                      >
                        <p className="text-sm md:text-lg lg:text-xl p-8">
                          {item.desc}
                        </p>
                      </motion.div>
                    ) : (
                      <div className="h-4"></div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            ))}
          </>
        ))}
        {len > 4 && (
          <div className="w-full flex justify-center ">
            {viewMore === 0 ? (
              <span
                onClick={() => setViewMore(len)}
                className="material-icons-outlined text-brown font-bold text-3xl  md:text-5xl cursor-pointer "
              >
                expand_more
              </span>
            ) : (
              <span
                onClick={() => setViewMore(0)}
                className="material-icons-outlined text-brown font-bold text-3xl  md:text-5xl cursor-pointer "
              >
                keyboard_arrow_up
              </span>
            )}
          </div>
        )}
      </div>
    </section>
  );
};
const firstSection = "text-white flex flex-col items-center py-2 md:px-16";
const acoridonContainer =
  "bg-brown w-11/12 flex justify-between items-center rounded-md px-4 py-1.5 mb-4 md:py-5 md:px-10 lg:py-4";
const expandedAcoridon =
  " bg-white text-black  rounded-md h-max  w-11/12 p-4 mb-4";
