import "../styles/home.css";
import { motion } from "framer-motion";
import { Section1 } from "../Components/home/section1";
import { Section2 } from "../Components/home/section2";
import { Section3 } from "../Components/home/section3";
import { Sectoin4 } from "../Components/home/section4";
import { useEffect, useState } from "react";

const Home = () => {
  const [initial, setInitial] = useState("Time Management");
  const [testimonials, setTestimonials] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [filteredData, setfilteredData] = useState<any[]>([]);
  async function fetchData() {
    const res = await fetch("https://arjunadb.herokuapp.com/book/");
    const response = await res.json();
    setData(response);
    setfilteredData(response.filter((sr: any) => sr.title === initial));
  }

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    setTestimonials(filteredData[0]?.testimonials);
  }, [filteredData]);
  useEffect(() => {
    fetchData();
    window.scroll(0, 0);
  }, [initial]);
  console.log(testimonials);

  return (
    <div>
      {/* 1st section */}
      <Section1 data={filteredData} />
      {/* 2nd section */}
      <Section2 data={filteredData} />

      {/* section 3 */}
      <Section3 data={testimonials} />
      {/* section-4 */}
      <Sectoin4 />
      {/* section 5 */}
      <section className={`sectionFive w-screen min-h-screen  ${firstSection}`}>
        <p className="text-black text-2xl font-bold text-center mt-16 md:text-4xl px-4">
          Other Titles in our wisdom library
        </p>
        <p className="text-center text-black mt-6 text-lg px-6 md:text-xl md:mb-16">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl feugiat
          et turpis id cras feugiat imperdiet viverra.
        </p>
        <div className="flex flex-col items-center space-y-6 md:space-y-0 md:grid md:grid-flow-col mt-8 w-9/12 md:w-10/12 mb-12 md:mb-0 md:justify-around md:pb-3 bottomBooks ">
          {data
            .filter((sr: any) => sr.title !== initial)
            .map((item: any) => (
              <motion.div
                onClick={() => setInitial(item.title)}
                whileHover={{ scale: 1.08 }}
                className="books flex flex-col text-black space-y-2 h-full"
              >
                <img src={item.image} className="bookimg " />
                <p className="booktext">{item.title}</p>
                <p className="booktext2">{item.subtitle}</p>
              </motion.div>
            ))}
        </div>
      </section>
      {/* section 6 */}
      <section className={` w-screen  bg-gray ${firstSection} `}>
        <span className="flex flex-col items-center md:flex-row w-full md:items-center md:px-12">
          <span className="w-full ">
            <p className="text-2xl font-bold text-black mt-6  text-center md:text-left w-full md:mt-0 md:text-xl">
              Don’t Delay Buy Now
            </p>
            <p className="text-black text-center text-xl mt-6 px-6 leading-8 md:text-left w-full md:px-0 md:mt-0 md:text-sm">
              Lorem ipsum dolor sit amet, consectetur
            </p>
          </span>
          <button className="bg-yellow text-xl font-bold py-4 w-10/12 rounded-md mb-8 mt-8 md:w-6/12 hover:opacity-90">
            BUY NOW
          </button>
        </span>
      </section>
    </div>
  );
};
export default Home;

const firstSection = "text-white flex flex-col items-center py-2 md:px-16";
