import Rout from "./router";

function App() {
  return (
    <div className="max-w-screen overflow-x-hidden">
      <Rout />
    </div>
  );
}
export default App;
