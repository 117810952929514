import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NavBar } from "./Components/navbar";
import Home from "./Pages/home";
import TestPage from "./Pages/testpage";
const Rout = () => {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/test" element={<TestPage />} />
      </Routes>
    </Router>
  );
};
export default Rout;
