import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";

interface Props {
  data: any;
}
export const Section3 = ({ data }: Props) => {
  const [viewMoreAuth, setViewMoreAuth] = useState(0);
  const [layoutId, setLayouId] = useState("");
  const [cardText, setCardText] = useState("");
  const [mobileId, setMobileId] = useState("");
  const [name, setName] = useState("");
  const [positon, setPosition] = useState("");
  const [hover, setHover] = useState(true);
  useEffect(() => {
    if (cardText === "") setHover(true);
    else setHover(false);
  }, [cardText]);

  const toggleID = (id: any) => {
    if (mobileId === id) setMobileId("");
    else setMobileId(id);
  };
  const checkHoverIn = () => {
    if (cardText === "") setHover(true);
    else setHover(false);
  };
  return (
    <section
      className={`thirdSection w-screen min-h-screen relative ${firstSection}`}
    >
      <p className="text-2xl font-bold mt-16 md:text-4xl mb-16">
        Appreciation for the Book
      </p>

      {/* website */}
      <motion.span
        className="hidden md:block"
        onMouseEnter={() => checkHoverIn()}
        onMouseLeave={() => checkHoverIn()}
      >
        <Carousel
          autoPlay={hover}
          interval={3000}
          infiniteLoop
          showArrows
          showStatus={false}
          showIndicators
        >
          {data
            ?.slice(0, Math.ceil(data.length / 4))
            .map((it: any, i: number) => (
              <AnimateSharedLayout type="crossfade">
                <motion.div>
                  <span className="md:grid md:grid-cols-2 cursoe-pointer py-12 ">
                    {data
                      ?.slice(2 * (i * 2), 2 * (i * 2) + 4)
                      .map((item: any) => (
                        <motion.div className="flex flex-col w-full items-center ">
                          <motion.div
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            transition={{ type: "spring" }}
                            className={`${authorCard}`}
                            onClick={() => {
                              setCardText(item.comment);
                              setLayouId(item.comment);
                              setName(item.name);
                              setPosition(item.position);
                            }}
                          >
                            <div className={`${cardprops}`}>
                              <img src="/cardprops.png" />
                            </div>
                            <p className="md:text-xl">
                              {item.comment.split(" ").slice(0, 20).join(" ")}
                              {item.comment.split(" ").length > 20 ? "..." : ""}
                            </p>
                            <p className="font-bold md:text-xl">{item.name}</p>
                            <p className="md:text-xl">{item.position}</p>
                          </motion.div>
                        </motion.div>
                      ))}
                  </span>
                </motion.div>
                <AnimatePresence>
                  {cardText !== "" && (
                    <motion.div
                      layoutId={layoutId}
                      onClick={() => {
                        setCardText("");
                      }}
                      className="absolute top-0 right-0 left-0 bottom-0 flex items-center px-32 justify-center "
                      style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
                    >
                      <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ type: "spring" }}
                        className={`bg-white flex flex-col justify-center items-center text-black space-y-6 py-8 px-8`}
                      >
                        <div className="w-14">
                          <img src="/cardprops.png" />
                        </div>
                        <p className="md:text-xl">{cardText}</p>
                        <p className="font-bold md:text-xl">{name}</p>
                        <p className="md:text-xl">{positon}</p>
                      </motion.div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </AnimateSharedLayout>
            ))}
        </Carousel>
      </motion.span>

      {/* mobile */}
      <span className=" md:grid-cols-2 md:hidden">
        {data?.slice(0, viewMoreAuth + 4).map((item: any) => (
          <motion.div className="flex flex-col w-full items-center ">
            <motion.div
              whileHover={{ scale: 1.4 }}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring" }}
              className={`${authorCard}`}
              onClick={() => {
                setCardText(item.comment);
                toggleID(item.comment);
              }}
            >
              <div className={`${cardprops}`}>
                <img src="/cardprops.png" />
              </div>
              <p className="md:text-xl">
                {mobileId !== item.comment
                  ? item.comment.split(" ").slice(0, 20).join(" ")
                  : cardText}
                {item.comment.split(" ").length > 20 &&
                mobileId !== item.comment
                  ? "..."
                  : ""}
              </p>
              <p className="font-bold md:text-xl">{item.name}</p>
              <p className="md:text-xl">{item.position}</p>
            </motion.div>
          </motion.div>
        ))}
      </span>
      <div className="w-full flex justify-center md:hidden">
        {viewMoreAuth === 0 ? (
          <span
            onClick={() => setViewMoreAuth(data.length)}
            className="material-icons-outlined text-white font-bold text-3xl  "
          >
            expand_more
          </span>
        ) : (
          <span
            onClick={() => setViewMoreAuth(0)}
            className="material-icons-outlined text-white font-bold text-3xl  "
          >
            keyboard_arrow_up
          </span>
        )}
      </div>

      {/*  */}
      <p className="mt-24 font-bold text-2xl mb-4 md:text-4xl">Lorem ipsum</p>
      <p className="text-xl text-center px-5">
        Lorem ipsum dolor sit amet, consectetur
      </p>
      <button className="bg-yellow text-xl font-bold py-4 w-10/12 rounded-md mb-16 mt-8 md:text-3xl hover:opacity-90">
        BUY NOW
      </button>
    </section>
  );
};
const firstSection = "text-white flex flex-col items-center py-2 md:px-16";
const authorCard =
  "bg-white w-10/12 h-max mt-24 flex flex-col items-center justify-start text-center p-4 text-black space-y-3 mb-14 md:mb-4 md:mt-4  md:space-y-6 md:p-6 md:h-full ";
const cardprops = "w-1/12";
