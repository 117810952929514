import "./burger.css";

import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";
export const NavBar = () => {
  const [open, setOpen] = useState(false);
  return (
    // navbar body
    <div className={`${navContainer}`}>
      <nav className={`${navbar}`}>
        {/* logo container */}
        <div className={`${logo_container}`}></div>
        {/* menu toggle */}
        <div className={`${burger_menu}`}>
          <div onClick={() => setOpen(!open)}>
            <div id="nav-icon4" className={`${open ? "open" : ""} `}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div className="md:flex space-x-20 text-mdtext-white hidden md:block text-white">
          <Link to="/">
            <p className={`${burgeritem}`}>Books </p>
          </Link>
          <Link to="/">
            <p className={`${burgeritem}`}>Webinar </p>
          </Link>
          <Link to="/">
            <p className={`${burgeritem}`}>Courses </p>
          </Link>
          <Link to="/">
            <p className={`${burgeritem}`}>Contests </p>
          </Link>
        </div>
      </nav>

      <AnimatePresence>
        {open && (
          <motion.div
            className={`${burger_body}`}
            onClick={() => setOpen(false)}
          >
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              className={`${burger_body_item_container}`}
            >
              <Link to="/" onClick={() => setOpen(false)}>
                <motion.p className={`${burger_item}`}>Books </motion.p>
              </Link>
              <Link to="/" onClick={() => setOpen(false)}>
                <p className={`${burger_item}`}>Webinar </p>
              </Link>
              <Link to="/" onClick={() => setOpen(false)}>
                <p className={`${burger_item}`}>Courses </p>
              </Link>
              <Link to="/" onClick={() => setOpen(false)}>
                <p className={`${burger_item}`}>Contests </p>
              </Link>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

// style variables
const navContainer = "";
const navbar =
  "bg-brown flex justify-between p-2  fixed top-0 left-0 right-0 z-10 md:justify-start md:items-center md:space-x-6";
const logo_container = "rounded-full bg-white w-10 h-10  ";
const burger_menu = " w-10 h-10 relative";
const burger_body =
  "bg-smoke-light w-full h-screen flex justify-end bg-transparent fixed top-14 bottom-0 left-0 right-0 z-10";
const burger_body_item_container = " flex flex-col w-3/5 h-min ";
const burger_item =
  "font-medium text-xl px-4 py-4 bg-white opacity-90 hover:text-yellow  ";
const burgeritem = "hover:text-yellow";
