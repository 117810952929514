import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";
const TestPage = () => {
  return (
    <div className="mt-20 text-black bg-gray min-h-screen">
      <Carousel>
        <div>
          <p>ok</p>
          <p>ok</p>
        </div>
        <div>
          <p>ok</p>
          <p>ok</p>
        </div>
      </Carousel>
    </div>
  );
};
export default TestPage;
